img {
    width: 90%;
    height: auto;
}

.side-nav-image {
  width: 200px;
  height: auto;
}

#nav-hover img:last-child {
    display: none;
    padding-bottom: 3.5px;  
  }
  #nav-hover:hover img:last-child {
    display: block;  
  }
  #nav-hover:hover img:first-child {
    display: none;  
  }

  .main-container .nihility {
    background: url('../assets/replace_nihilty.jpg');
  }


  .main-container {
      /* see inline style in component for background image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-left: 80px;
    margin-right: 80px;
  }

  .col-display {
      height: 100vh;
      display: flex;
      justify-content: space-between; 
      margin-left: 20px; 
      margin-right: 20px; 
  }

  .page-items {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
  }

  .page-item img{
    height: 100px;
    width: auto;
  }


  

  .other-items-nihility-kunstkrud-zounds {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
  }

  .linx {
    padding-top: 50px;
    padding-right: 50px;
  }

  .nav-pair {
    display: flex;
  }

  .nav-pair .contact {
    padding-bottom: 0px;
}

  .nav-pair img {
    height: 100px;
    width: 100px;
  }

  @media(max-width: 820px){

    .main-container {
        height: 100vh;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 20px;
        /* padding-left: 20px;
        padding-right: 20px; */
      }
   
    .nav-pair #nav-hover img:first-child {
        display: none;  
    }
    .nav-pair #nav-hover img:last-child {
        display: block;  
    }
    img {
        width: 60%;
        height: auto;
    }

    #img-hover {
      display: none;
    }

       .yellow-back {
          height: 40%;
          width: auto;
          padding-left: 30px;
      }
   
  }

  @media(max-width: 500px){

    .main-container {
        height: 100vh;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 20px;
      }

      .col-display {
          display: flex;
          flex-direction: column;
      }

      .yellow-back {
        height: 150px;
        width: auto;
        padding-left: 150px;
        padding-bottom: 150px;
      }

      .page-item img{
        max-width: 300px;
        height: auto;
      }

      .nav-pair {
        padding-top: 10px;
        padding-left: 30px;
        margin-left: 0px;
      }
    

}

@media(max-width: 350px){
  .page-item img{
    max-width: 150px;
    height: auto;
  }
}
  