.super-container {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 40px;
  }

.small-conditional-header {
    display: none;
}

.col-display {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.grid {
    width: 30vw;
}

.grid {
    height: 90vh;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .grid img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

/* .grid-item {

} */

.display-frame {
    width: 45vw; 
}

.display-frame-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    text-align: start;
    height: 100vh;
 
}

.display-frame-image img {
    align-items: center;
    width: 70%;
}

.display-frame-image h4 {
    align-items: center;
    width: 70%;
}

.other-items {
    width: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
    padding-right: 10px;
}

.other-items img {
    max-width: 200px;
    height: auto;
}

/* .yellow-back img {
    padding: 0;
    margin: 0;
} */




/* check this breakpoint!! */
@media(max-width: 820px){
    .super-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        padding-top: 10px;
      }
    
    .small-conditional-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 40px;
        margin-right: 40px;
    }

    .small-conditional-header img{
        height: 80px;
        width: auto;
    }

    .grid {
        width: 90vw;
        height: 90vh;
        margin-top: 10px;
        margin-left: 20px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(3, 1fr);
        overflow-x: hidden;
        overflow-y: scroll;
      }
      
      .grid img {
        width: 220px;
        height: 220px;
        object-fit: cover;
      }
  

    .display-frame {
        display: none;
    }

    .other-items {
        display: none;
    }
}

@media(max-width: 500px){

    .small-conditional-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .small-conditional-header img{
        height: 50px;
        width: auto;
    }

    .super-container {
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 0px;
      }

    .col-display {
        margin-left: 10px;
        margin-right: 10px;
    }

    .grid {
        width: 90vw;
        height: 85vh;
        margin-left: 10px;
        margin-top: 10px;
        padding-bottom: 20px;
        display: grid;
        justify-items: center;
        grid-gap: 8px;
        grid-template-columns: repeat(1, 1fr);
        /* overflow-x: hidden;
        overflow-y: scroll; */
      }
      
      .grid img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
}