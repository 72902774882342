.side-nav-item {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 60px;
  }

.menu-back-image {
   
}

#menu-back-nav img {
    height: 100%;
    width: auto;
} 

/* only relevant for small screens */
.small-yellow-back img {
  height: 30px;
  width: auto;
}

/* Menu Back Nav  - controls function of component not display on page*/
#menu-back-nav img:last-child {
    display: none;
    padding-bottom: 3.5px;  
  }
  #menu-back-nav:hover img:last-child {
    display: block;  
  }
  #menu-back-nav:hover img:first-child {
    display: none;  
  }
  #menu-back-nav {
    padding-left: 0px;
  }
  

  @media(max-width: 820px) {
    #menu-back-nav {
        padding-top: 140px;
      }
  }

  @media(max-width: 500px) {
    #menu-back-nav {
        padding-top: 0px;
      }  
    #menu-back-nav img {
        height: 70%;
        width: auto;
    }  
  }