


.spin-container {
    margin: auto;
    width: 200px;
    text-align: center;
  }

.spinner {
    margin: auto;
    margin-top: 300px;
    width: 200px; 
    height: 200px;
    -webkit-animation: spin 1.5s infinite linear;
  }
  
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
  