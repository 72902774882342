.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }


/* MENU NAV */

.menu-container {
    background: url('../assets/yellow-beast-front-page.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100vh;
  }



.menu-nav-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    height: 100vh;
  }
  
  .menu-nav-container > div {
    height: 210px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


.nav-image {
    height: 26rem;
    width: auto;
  }
  
  .menu-nav-a {
    grid-column: 1/3; 
  }
  
  .menu-nav-a:hover {
    background: url("../assets/nihility.gif");
    background-size: 36rem;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .menu-nav-b:hover {
    background: url("../assets/kunstkrud2.gif");
    background-size: 36rem;
    background-repeat: no-repeat;
    background-position: center;
  } 
  
  .menu-nav-c:hover {
    background: url("../assets/zounds.gif");
    background-size: 36rem;
    background-repeat: no-repeat;
    background-position: center;
  } 


  /* Nihility */

#nihility img:last-child {
  display: none;
  padding-bottom: 3.5px;
}

#nihility:hover img:last-child {
  display: block;
}

#nihility:hover img:first-child {
  display: none;
}









@media(max-width: 820px) {

  #small-back {
    display: block;
  }

  /* Intro */  

  .menu-nav-a:hover {
    height: 20rem;
    padding: 10px;
  }

  .menu-nav-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }

  .menu-nav-container > div {
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  .menu-nav-a {
    background-image: url("../assets/nihility.gif");
    background-size: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  
  .menu-nav-b {
    background: url("../assets/kunstkrud2.gif");
    background-size: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  } 
  
  .menu-nav-c {
    background: url("../assets/zounds.gif");
    background-size: 30rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }   
  
}


@media(max-width: 500px){

  .menu-nav-a:hover {
    height: 20rem;
    padding: 10px;
  }

  .menu-nav-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }

  .menu-nav-container > div {
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .menu-nav-a {
    background: url("../assets/nihility.gif");
    background-size: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  
  .menu-nav-b {
    background: url("../assets/kunstkrud2.gif");
    background-size: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  } 
  
  .menu-nav-c {
    background: url("../assets/zounds.gif");
    background-size: 30rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  } 

  .home-container img {
    width: 100%;
    height: auto;
  }


}