/* Global Styles */

body, html {
  background-color: black;
  margin: 0px;
  height: 100%;
  color: #ccc;
  font-family: Geneva, Arial, Helvetica;
  font-size: 12px;
  display: absolute;
}

a {
  text-decoration: none;
}

a, a:active, a:visited {
  color: white;
}

p {
  margin-top: 14px;
}

h2 {
  font-size: 14px;
  color: white;
  margin-top: 48px;
}

.home-container {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100vh;
}

.intro-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.intro-container img {
  margin: auto;
  width: 40%;
  height: auto;
}



.spacer {
  padding-top: 30px;
}


/* Side Nav */
.side-nav-image {
  width: 200px;
  height: auto;
}


#small-back {
  display: none;
}


/* Hover States for Nav Elements */

#nav-hover img:last-child {
  display: none;
  padding-bottom: 3.5px;  
}
#nav-hover:hover img:last-child {
  display: block;  
}
#nav-hover:hover img:first-child {
  display: none;  
}



/* Words */

#words img:last-child {
  display: none;  
  padding-bottom: 3.5px; 
}
#words:hover img:last-child {
  display: block;  
}
#words:hover img:first-child {
  display: none;  
}

/* Gigs */

#gigs img:last-child {
  display: none;
  padding-bottom: 3.5px;  
}
#gigs:hover img:last-child {
  display: block;  
}
#gigs:hover img:first-child {
  display: none;  
}


/* Nihility */

#nihility img:last-child {
  display: none;
  padding-bottom: 3.5px;
}

#nihility:hover img:last-child {
  display: block;
}

#nihility:hover img:first-child {
  display: none;
}

/* News */

#news img:last-child {
  display: none;  
  padding-bottom: 3.5px; 
}
#news:hover img:last-child {
  display: block;  
}
#news:hover img:first-child {
  display: none;  
}





@media(max-width: 850px){


  .super-container {
    grid-template-columns: repeat(1, 1fr)
  }

  .skulpchur-container {
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
  }

  .skulpchur-grid-item img{
    width: 140px;
    height: 140px;
  }
}



@media(max-width: 820px) {

  .home-container {

    background-size: auto 100vw;
  }

  .intro-container {
    padding-top: 0px;
  }

  .intro-container img {
    width: 50%;
  }

  #spacer {
    display: block;
  }

  .small-nav {
    display: flex
  }
  
  /* Landing page / Home */
  .home-container img {
    width: 100%;
    height: auto;
  }


  

  #IoIosArrowBack {
    display: none;
  }
  #IoIosArrowForward {
    display: none
  }

  
}



@media(max-width: 700px) {

  .menu-nav-b, .menu-nav-c {
    grid-column: 1/3;
  }


}




@media(max-width: 500px) {

  .home-container {

    background-size: auto 100vw;
  }

  .intro-container {
    padding-top: 0px;
  }

  .intro-container img {
    width: 75%;
  }


 #small-back-mobile {
   display: flex;
 }

 .small-back-conditional {
   display: block;
   margin: 40px;
 }

  .menu-back-image {
    width: 80%;
  }



}

