.text-super-container {
    padding-top: 40px;
}

/* .small-conditional-header {

} */

.text-layout-cols {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.back-btn-container {
    width: 20vw;
    padding: 30px;
}

.text-display {
    width: 40vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
}

/* .gigs-item {

} */

.other-items {
    width: 18vw;
    padding-left: 20px;
}

@media(max-width: 820px){
    .text-layout-cols {
        height: 100vh;
        display: block;
        margin: 20px;
        
    }
    .back-btn-container {
        display: none;
    }
    .text-display {
        width: 80vw;
        height: 86%;
        padding: 0px 50px;
    }
}

@media(max-width: 500px){
    .text-layout-cols {
        height: 100vh;
        display: block;
        margin: 10px;
    }
    .back-btn-container {
        display: none;
    }
    .text-display {
        width: 90vw;
        height: 85%;
        padding: 0px 10px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}



/* Linx */


.linx-container {
    /* grid-column: 1/ span 4; */
    overflow: scroll;
    background-color: rgb(220,0,0);
    /* margin-left: 50px;
    margin-right: 70px; */
  }
  
  .linx-container p {
    text-align: left;
    padding-left: 20px;
  }
  
  .letterspaced {
    letter-spacing: 2px;
  }
  
  
  .linx-container a, .linx-container a:active, .linx-container a:visited {
    color: rgb(2, 114, 2);
    text-transform: uppercase;
    padding-left: 6px;
    
    
  }
  
  .linx-container a:hover {
    color: rgb(100,200,100);
  } 

  /* Contact */

  .contact:hover {
    color: lime;
  }
  