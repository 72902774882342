.single-artwork-super-container {
    padding-top: 40px;
}


.single-artwork-cols {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.back-btn-container {
    width: 20vw;
    padding: 30px;
  
}

.artwork-display {
    width: 50vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.display img {
    
}

img {
    width: 60%;
    height: auto;
}


.btn-container {    
    display: flex;
    justify-content: space-between;
}

.other-items {
    width: 18vw;
    padding-left: 20px;
}

.musick-super-container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-left: 80px;
    margin-right: 80px;
    padding-top: 40px;
    height: 100vh;
  }
  
  .musick-container {
    height: 100vh;
    grid-column: 2/4;
    overflow-y: auto;
  }

  /* Aural Abject */

.audio-item-container {
  
  padding: 4px;
}

.audio-container {
    display: flex;
    color: red;
  }
  
  .audio-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    width: 500px;
    height: 55px;
    padding: 5px;
    padding-right: 20px;
  }
  
  .audio-item h3 {
    color: red;
  }
  
  #btn-audio {
    background: inherit;
    color: white;
    border-width: 0px;
    /* height: 2em;
    width: 2em; */
    height: 100%;
    width: auto;
   
  }
  
  .audio-item #pause, .audio-item #play {
    height: 2em;
    width: 2em;
    /* padding: 10px; */
    /* margin: 10px; */
  }

@media(max-width: 820px){

  .audio-item-container {
    border: white;
    border-style: solid;
    border-width: 1px;
    padding: 4px;
  }

    .single-artwork-cols {
        display: block;
        margin-top: 20px;
    }

    img {
        width: 90%;
        height: auto;
    }

    .back-btn-container {
        display: none;
    }
    .artwork-display {
        width: 90vw;
        margin: auto;
    }
    .other-items {
        display: none;
    }
}

@media(max-width: 500px){

    .single-artwork-cols {
        display: block;
        margin-top: 40px;
    }

    .audio-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px;
        width: 300px;
        padding: 8px;
        padding-bottom: 20px;
        background: #30302f;
      }
      
      .audio-item h3 {
        color: red;
      }

      .audio-item #pause, .audio-item #play {
        height: 1em;
        width: 1em;
      }

}