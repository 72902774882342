.single-artwork-super-container {
    padding-top: 40px;
}

/* .small-conditional-header {

} */

.single-artwork-cols {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.back-btn-container {
    width: 20vw;
    padding: 30px;
  
}

.artwork-display {
    width: 50vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.video {
    /* background: red; */
    width: 100%;
}

#vimeo {
    width: 100%;
}

.display img {
    
    width: 60%;
    height: auto;
}

/* img {
    width: 60%;
    height: auto;
} */


.btn-container {    
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
}

.other-items {
    width: 18vw;
    padding-left: 20px;
}


.artwork-modal-background {
    width: 100vw;
    height: 90vh;
    background-color: black;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    
    
}

.artwork-modal-container {
    top: 0;
    width: 90vw;
    height: 90vh;
    background-color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    padding: 10px;
    border: white;
    border-style: solid;
    border-width: 2px;
}

.artwork-modal-container img {
    /* max-height: 100%;
    width: auto; */
    
    
}

.close-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 100px;
    background: none;
    border: none;
    font-size: 3rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

.zoom-btn {
    background: none;
    border: none;
    cursor: pointer;
}






@media(max-width: 820px){

    .single-artwork-cols {
        display: block;
        margin-top: 20px;
    }

    .display img {
    
        width: 110%;
        height: auto;
    }

    /* img {
        width: 90%;
        height: auto;
    } */

    .back-btn-container {
        display: none;
    }
    .artwork-display {
        width: 90vw;
        margin: auto;
    }
    .other-items {
        display: none;
    }

    .zoom-btn {
        display: none;
    }
}

@media(max-width: 500px){

    .single-artwork-cols {
        display: block;
        margin-top: 40px;
    }

    .display img {
    
        width: 100%;
        height: auto;
    }

}